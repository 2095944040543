<template>

  <header class="header">
    <div class="header-content max-container">
        <a href="/" class="header-content__container-logo">
        </a>
    </div>
  </header>
</template>

<script>



  export default {
    name: "HeaderComponent",

    data() {
      return {
        urlImage: require('../assets/images/logo.png'),
        altImage: 'Bet America',
      }
    },
  };
</script>
